


























import Vue from 'vue';
import { assert } from 'chai';
import Picture from '@/dtos/picture';

export enum CellType {
  picture,
  uploadCTA,
}

export class PictureCell {
  _type: CellType;
  _picture?: Picture;

  constructor(t: CellType, p?: Picture) {
    assert(t == CellType.uploadCTA || p != null);
    this._type = t;
    this._picture = p;
  }

  get isCTA() {
    return this._type === CellType.uploadCTA;
  }

  get showLoading() {
    return this._picture?.isDraft;
  }

  get isEditable() {
    return this._picture?.isMine;
  }

  get pictureId() {
    return this._picture?.id;
  }

  get url() {
    return this._picture?.thumbUrl || this._picture?.url;
  }
}

export default Vue.extend({
  props: {
    uploadedCount: {
      type: Number,
      required: true,
    },
    maxUploadCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    uploadStatString(): string {
      return [
        'noch',
        this.maxUploadCount - this.uploadedCount,
        '/',
        this.maxUploadCount,
      ].join(' ');
    },
  },
  methods: {
    onFileSelected(event: Event) {
      const files = (event.target as HTMLInputElement).files;
      if (files && files.length > 0) {
        const filesToUpload = Array<File>();
        const allowedCount = this.maxUploadCount - this.uploadedCount;
        for (let i = 0; i < Math.min(files.length, allowedCount); i++) {
          filesToUpload.push(files[i]);
        }
        this.$emit('file', filesToUpload);
      }
    },
  },
});
