







































import Vue from 'vue';
import Picture from '@/dtos/picture';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import EditButton from '@/components/Mobile/Button/EditButton.vue';
import DoneButton from '@/components/Mobile/Button/DoneButton.vue';
import TourPictureCell, {
  CellType,
  PictureCell,
} from '@/views/Mobile/TourDetail/TourPictureCell.vue';

import { mapGetters } from 'vuex';
import { formatShortDate } from '@/utils/date_time';
import UploadPictureCell from './UploadPictureCell.vue';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm';
import 'photoswipe/dist/photoswipe.css';

export default Vue.extend({
  components: {
    Scaffold,
    EditButton,
    DoneButton,
    TourPictureCell,
    UploadPictureCell,
  },
  props: {
    tourId: {
      type: String,
      required: true,
    },
    tourName: {
      type: String,
      required: false,
      default: null,
    },
    tourDate: {
      type: Date,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters({
      pictures: 'tourpictures/pictures',
      canUpload: 'tourpictures/canUpload',
      canEdit: 'tourpictures/canEdit',
      canEditAll: 'tourpictures/canEditAll',
      uploadedCount: 'tourpictures/uploadedCount',
      maxUploadCount: 'tourpictures/maxUploadCount',
    }),
    title(): string {
      const components: string[] = [];
      if (this.tourName) components.push(this.tourName);
      if (this.tourDate) components.push(formatShortDate(this.tourDate));
      if (components.length == 0) components.push('Tour Bilder');
      return components.join(' ');
    },
    cells(): PictureCell[] {
      const cells = (this.pictures as Picture[]).map(
        (p) =>
          new PictureCell({
            t: CellType.picture,
            id: p.id,
            url: p.thumbUrl || p.url,
            isEditable: p.isMine || this.canEditAll,
            isDraft: p.isDraft,
            isLoading: p.isBeingProcessed,
          })
      );
      if (this.canUpload) {
        cells.unshift(new PictureCell({ t: CellType.uploadCTA }));
      }
      return cells;
    },
    hasDrafts(): boolean {
      return (this.pictures as Picture[]).some((p) => p.isDraft);
    },
  },
  async mounted() {
    const dispatch = this.$store.dispatch;
    dispatch('toggleLoading', true);
    await dispatch('tourpictures/bindPictures', this.tourId);
    dispatch('toggleLoading', false);
  },
  destroyed() {
    const dispatch = this.$store.dispatch;
    dispatch('tourpictures/clear');
  },
  methods: {
    onFileSelected(files: File[]) {
      this.isEditing = false;
      files.forEach((f) => this.$store.dispatch('tourpictures/addDraft', f));
    },
    onTap(index: number) {
      if (this.cells[0].isCTA) index -= 1;
      this.isEditing = false;
      const options = {
        dataSource: this.pictures.map((p: Picture) => {
          return { src: p.url, width: p.width, height: p.height };
        }),
        bgOpacity: 0.9,
        showHideAnimationType: 'none',
        index: index,
      };
      const pswp = new PhotoSwipe(options);
      if (window.MobileApp) {
        window.MobileApp.postMessage(`Rotate`);
        pswp.on('close', () => window.MobileApp.postMessage(`Portrait`));
      }
      pswp.init();
    },
    onUpload(pictureId: string) {
      this.$store.dispatch('tourpictures/uploadDraft', pictureId);
    },
    onCancelUpload(pictureId: string) {
      this.$store.dispatch('tourpictures/removeDraft', pictureId);
    },
    onDelete(pictureId: string) {
      this.$store.dispatch('tourpictures/removePicture', pictureId);
    },
  },
});
