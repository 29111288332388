




















import Vue from 'vue';
export default Vue.extend({
  props: {
    green: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
});
