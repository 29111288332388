















import Vue from 'vue';
export default Vue.extend({
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
});
