









































import Vue from 'vue';
import DeleteButton from '@/components/Mobile/Button/DeleteButton.vue';
import CancelButton from '@/components/Mobile/Button/CancelButton.vue';
import ConfirmUploadButton from '@/components/Mobile/Button/ConfirmUploadButton.vue';
import LoadingIndicator from '@/components/Mobile/LoadingIndicator.vue';
import { assert } from 'chai';

export enum CellType {
  picture,
  uploadCTA,
}

interface PictureCellEssence {
  t: CellType;
  id?: string;
  url?: string;
  isEditable?: boolean;
  isDraft?: boolean;
  isLoading?: boolean;
}

export class PictureCell {
  _type: CellType;
  _id?: string;
  _url?: string;
  _isPictureEditable: boolean;
  isDraft: boolean;
  isLoading: boolean;

  constructor(e: PictureCellEssence) {
    assert(e.t == CellType.uploadCTA || e.url != null);
    this._type = e.t;
    this._id = e.id;
    this._url = e.url;
    this._isPictureEditable = e.isEditable || false;
    this.isLoading = e.isLoading || false;
    this.isDraft = e.isDraft || false;
  }

  get isCTA() {
    return this._type === CellType.uploadCTA;
  }

  get isEditable() {
    return this._isPictureEditable && !this.isDraft;
  }

  get pictureId() {
    return this._id;
  }

  get url() {
    return this._url;
  }
}

export default Vue.extend({
  components: {
    DeleteButton,
    CancelButton,
    ConfirmUploadButton,
    LoadingIndicator,
  },
  props: {
    cell: {
      type: PictureCell,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    isEditing: function (newIsEditing) {
      if (!newIsEditing) this.isAboutToDelete = false;
    },
  },
  data() {
    return {
      isAboutToDelete: false,
    };
  },
  methods: {
    onImageClick() {
      if (!this.cell.isCTA) this.$emit('click');
    },
    onUpload(pictureId: string) {
      this.$emit('upload', pictureId);
    },
    onCancelUpload(pictureId: string) {
      this.$emit('cancelUpload', pictureId);
    },
    onDelete(pictureId: string) {
      this.isAboutToDelete = false;
      this.cell.isLoading = true;
      this.$emit('delete', pictureId);
    },
  },
});
